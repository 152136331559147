/* BackToTopButton.module.css */
.backToTopButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: fixed; /* Fixed position */
    bottom: 20px; /* Positioned at the bottom of the page */
    right: 20px; /* Positioned to the right */
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    border: none;
    cursor: pointer;
  }
  
  .owlIcon {
    margin-right: 8px; /* Space between the icon and text */
    width: 24px; /* Adjust based on your design */
    height: auto;
  }