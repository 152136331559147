.main_container {
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  flex: 1 0;
}

