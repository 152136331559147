.main_container {
  margin: 0 auto;
  max-width: 900px;
  padding: 0 16px;
}

.title {
  color: #282882;
  text-align: center;
  font-family: TestDomaineText;
  font-style: normal;
  font-weight: 700;
  line-height: 116.667%;
  font-size: calc((100vw - 414px)/(1920 - 414) * (60 - 20) + 32px);
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (81 - 43) + 43px);
  margin-top: calc((100vw - 414px)/(1920 - 414) * (81 - 34) + 34px);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.input {
  border-radius: 33px;
  border: 1px solid #282882;
  background: #FFF;
  padding: 8px 0;
  padding-left: 37px;
  padding-right: 13px;
  box-sizing: border-box;
  height: 50px;
  display: block;
  width: 100%;
  max-width: 625px;
  box-sizing: border-box;
  position: relative;
  color: #282882;
  font-family: Mont;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input__textarea {
  font-family: Mont;
  max-height: 186px;
  height: auto;
  border-radius: 16px;
}


.input::placeholder {
  color: #282882;
  font-family: Mont;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input__textarea::placeholder {
  color: #4E4E4E;
}


.chekbox_container {
  display: flex;
  gap: 6px;
}

.checkbox__label {
  font-weight: 500;
}


.button {
  color: #4E4E4E;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 112.5%;
  text-transform: uppercase;
  border-radius: 46px;
  width: 290px;
  height: 75px;
  border: 1px solid #434343;
  margin: 0 auto;
  transition: all .2s ease-out;
  cursor: pointer;
  margin-bottom: 20px;
}

.button:hover {
  background: #282882;
  color:  #fff;
}

.link {
  margin: 28px 0;
  text-decoration: none;
  color: #4E4E4E;
  height: 100%;
  text-align: center;
  display: block;
}

.button:hover .link {
  color:  #fff;
}

.button_contact {
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  display: flex;
  width: 290px;
  height: 75px;
  padding: 12px 25px 11.19px 25px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 46px;
  border: 0;
  color: white;
  background: #EC5E43;  
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  margin-top: calc((100vw - 320px)/(1920 - 320) * (52 - 31) + 31px);
  margin-bottom: 24px;
  cursor: pointer;
}

.button_contact:hover {
  background-color: #292982;
}

.request_meassage {
  color: #EC5E43;
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 186.429%;
  text-align: center;
  margin-bottom: 64px;
}



@media (max-width: 900px) {
  .request_meassage {
    margin-bottom: 69px;
  }

  .button_contact {
   
    margin-bottom: 30px;
  }
}