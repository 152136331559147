.spoiler {
  color: #BEBEDA;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height:  145%;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 6px;
}

.title {
  color: #282882;
  font-family: TestDomaineText;
  font-size: calc((100vw - 414px)/(1920 - 414) * (55 - 32) + 32px);
  font-style: normal;
  font-weight: 600;
  line-height: 120%; 
  margin: 0;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (39 - 20) + 20px);
}

.sub_title {
  color: #4E4E4E;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 12) + 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 26.1px; 
  margin: 22px 0 70px;
  margin: 0 auto;
  text-align: center;
  max-width: 600px;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (102 - 30) + 30px);
}

.swipper_wrapper {
  display: flex;
  align-items: center;
}

.function_container {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  max-width: 1265px;
  gap: 16px;
  margin: 0 auto;
}

.function_item {
  border-radius: 20px;
  border: 2px solid #BEBEDA;
  transition: box-shadow .3s ease, outline .001s;
  display: flex; 
  flex-direction: column;
  flex: 0 0 32%;
  justify-content: center;
  align-items: center;
  padding: 35px 8px 53px;
  box-sizing: border-box;
}

.function_item__swiper {
  /* border: 2px solid #BEBEDA; */
  border-radius: 20px;
  transition: box-shadow .3s ease, outline .001s;
  display: flex; 
  flex-direction: column;
  align-items: center;
  padding: 51px 41px 41px;
  height: 100%;
}

.function_item__swiper:hover {
  box-shadow: 0px 4px 41px 0px rgba(236, 94, 67, 0.15);
}

.slider_mobile {
  height: auto !important;
  padding:  20px 25px 40px 25px;
}

.function_item__swiper_desktop {
  outline: 2px solid #BEBEDA;
  transition: box-shadow .3s ease, outline .001;
}

.function_item__swiper_desktop:hover {
  outline: 0;
  box-shadow: 0px 4px 41px 0px rgba(236, 94, 67, 0.15);
}

.function_item:hover {
  outline: 0;
  box-shadow: 0px 4px 41px 0px rgba(236, 94, 67, 0.15);
}

.slider {
  height: auto !important;
  padding: 40px 25px !important;
}

.function_item_image {
  width: 97px;
  height: 87px;
  object-fit: contain;
  margin: 0 0 30px;
}

.function_item_title {
  /* flex: 1; */
  display: block;
  align-items: center;
  color: #282882;
  font-family: TestDomaineText;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.24px;
  text-align: center;
  margin: 0 0 43px;
  overflow: hidden; /* Предотвращает выход за границы контейнера */
  word-wrap: break-word; /* Разрывает длинные слова */
  max-width: 200px;
  text-align: center;
}

.function_item_sub_title {
  color: #4E4E4E;
  text-align: center;
  font-family: Mont;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  margin: 0;
  max-width: 273px;
}

.button {
  color: #282882;
  text-align: center;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 46px;
  width: 290px;
  height: 75px;
  border: 1px solid #282882;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-out;
  cursor: pointer;
  margin: 0 auto;
  margin-top: calc((100vw - 414px)/(1920 - 414) * (60 - 50) + 50px);
  text-decoration: none;
}

.button:hover {
  background: #282882;
  color: #fff;
}

.wrapper {
  width: calc((100vw - 414px)/(1920 - 414) * (600 - 355) + 355px);
  /* width: 355px; */
  display: none !important;
  padding:  0 0 40px 0;
}

.swiper_wrapper {
  max-width: 1286px;
  margin: 0 auto;
}


.wrapper_desktop {
  display: flex;
}

.swiper_button_prev ,.swiper_button_next {
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: .2s ease;

}

.swiper_button_prev:hover ,.swiper_button_next:hover {
  opacity: .6;
}


.swiper_button_prev {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.swiper_button_next {
  /* transform: rotate(135deg);
  -webkit-transform: rotate(135deg); */
}

.spiner {
  margin: 0 auto;
  max-width: 100px;
}

.failed_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  gap: 12px;
}

.advice_title {
  text-align: center;
}

.swiper_button_next__mobile, .swiper_button_prev__mobile {
  display: none;
}

@media (max-width: 1024px) {
  .function_container {
    display: none;
  }

  .wrapper_desktop {
    display: none !important;
  }

  .swiper_button_next__desktop , .swiper_button_prev__desktop {
    display: none;
  }

  .swiper_button_next__mobile, .swiper_button_prev__mobile {
    display: block;
  }

  .swipper_wrapper {
    max-width: 500px;
    margin: 0 auto;
  }

  .wrapper {
    display: block !important;
  } 

  

 .wrapper div {
  margin: 0 auto;

 }

 .wrapper {
  /* outline: 2px solid #BEBEDA; */
  border-radius: 20px;

 }

 
  .function_item__swiper {
    border: 2px solid #BEBEDA;
  }

}


@media (max-width: 720px) {
  .swiper_button_next__mobile, .swiper_button_prev__mobile {
    display: none;
  }

  .function_item__swiper {
    padding: 31px 41px 41px;
  }

  .slider_mobile {
    height: auto !important;
    padding:  20px 0 40px 0;
  }
}