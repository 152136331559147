.main_container {
}

.background {
  background: #79FF91;
  padding-top: 32px;
  padding-bottom: 1px;
  padding-right: 31px;
  padding-left: 31px;

}

.title {
  color: #303030;
  font-weight: 700;
  line-height: calc((100vw - 414px)/(1920 - 414) * (70 - 40) + 40px);
  font-size: calc((100vw - 414px)/(1920 - 414) * (60 - 32) + 32px);
  max-width: 630px;
  margin-bottom: 18px;
}

.sub_title {
  max-width: 1270px;
  font-weight: 500;
  margin-bottom: 48px;

}

.spoiler {
  color: #30D94E;
  text-align: center;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.1px; /* 145% */
  text-transform: uppercase;

}

.function_container {
  margin-top: 50px;
  padding-bottom: 50px;
}

.function_slider_wrapper {
  margin-bottom: 30px;
  padding: 0 16px;
}

@media (max-width: 600px) {

  .title {
    margin-bottom: 34px;
  }
  .function_slider_wrapper {
    padding: 0;
  }

}
