.articles {
	padding: 29px 0 93px;
}

.spiner {
  margin: 0 auto;
  max-width: 100px;
  margin-top: 140px;
}

.failed_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  gap: 15px;
  margin-bottom: 60px;
  margin-top: 140px;
}

.advice_title {
  text-align: center;
}

.articles_container {
	max-width: 1470px;
	padding: 0 15px;
	margin: 0 auto;
	box-sizing: border-box;
	display: flex;
	gap: 15px;
}

.articles_aside {
	flex: 0 0 560px;
}

.link {
  text-decoration: none;
}

.pagination {
	background-color: red;
}

.articles_wrapper {
	padding-bottom: 50px;  
  height: 100%;
}

.articles_wrapper > div:nth-of-type(2)  {
  display: flex;
  margin-left: auto;
}

.articles_slide {
	width: 100% !important;
}

.articles_items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
  gap: 15px;
  height: 100%;
}

.articles_item {
  height: 352px;
	display: flex;
	gap: 22px;
	padding: 18px 18px 18px 26px;
	border-radius: 20px;
	border: 1px solid hsl(0, 0%, 85%);
	cursor: pointer;
}

.articles_item:not(:last-child) {
	margin-bottom: 15px;
}

.articles_item:hover .articles_title {
	color: #ec5e43;
	text-decoration: underline;
}

.articles_item_big {
	grid-row: 1/3;
	flex-direction: column;
	height: auto;
	margin-bottom: 50px;
  padding: 48px 30px 35px 31px;
  max-width: 861px;

}

.articles_text {
	flex: 1;
}

.articles_title {
	color: #282882;
	font-family: TestDomaineText;
	font-size: 32px;
	font-weight: 700;
	line-height: 40px;
	margin-bottom: 8px;
	transition: color 0.3s ease 0s;
}

.articles_title_big {
	font-size: calc((100vw - 414px) / (1920 - 414) * (64 - 40) + 40px);
	line-height: 109%;
	margin-bottom: 15px;
}

.articles_suptitle {
	color: #9d9d9d;
	font-family: Geometria;
	font-size: 11px;
	margin-bottom: 24px;
  text-transform: uppercase;
}

.articles_subtitle {
	color: #9d9d9d;
	font-family: Geometria;
	font-size: 13px;
	font-style: italic;
	font-weight: 500;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.articles_subtitle_big {
	-webkit-line-clamp: 5;
}

.articles_image {
	border-radius: 20px;
	flex: 0 0 207px;
	height: calc((100vw - 414px) / (1920 - 414) * (316 - 124) + 124px);
	overflow: hidden;
	position: relative;
}

.articles_image_big {
	width: 100%;
	flex: 0 0 316px;
	margin-top: calc((100vw - 414px) / (1920 - 414) * (40 - 20) + 20px);
}

.articles_image img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
}

.articles_content {
	display: flex;
}

@media (max-width: 1024px) {
  .articles {
    padding: 43px 0 67px;
  }

	.articles_container {
		flex-direction: column-reverse;
    gap: 12px;
	}

  .articles_wrapper > div:nth-of-type(2)  {
    display: block;
  }

  .articles_items {
    gap: 12px;
  }

  .articles_item {
    flex-direction: column-reverse;
    display: flex;
    gap: 24px;
    padding: 23px 24px 32px 26px;
    border-radius: 20px;
    border: 1px solid #dadada;
    cursor: pointer;
    height: auto;
  }

  .articles_image {
    flex: 0 0 124px;
  }

  .articles_image_big {
    flex: 0 0 316px;

  }

	.articles_item_big {
		flex-direction: column;
		margin-bottom: 0;
    padding: 21px 24px 31px 31px;
	}
}
