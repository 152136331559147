.main_container {
	padding: 0 16px;
	box-sizing: border-box;
	margin: 0 auto;
	padding-top: calc((100vw - 414px) / (1920 - 414) * (60 - 20) + 20px);
	padding-bottom: calc((100vw - 414px) / (1920 - 414) * (112 - 40) + 40px);
	box-sizing: border-box;
	max-width: 1438px;
}

.wrapper {
	max-width: 1148px;
	padding-bottom: 49px;
}

.failed_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 900px;
	margin: 0 auto;
	gap: 12px;
	margin-bottom: 60px;
}

.advice_title {
	text-align: center;
}

.spiner {
	margin: 0 auto;
	max-width: 100px;
  margin-top: 200px;
}

.new_container {
	background-color: white;
	display: flex;
	justify-content: space-between;
	gap: 32px;
	border-radius: 20px;
	border: 1px solid #dadada;
	padding: 59px 30px 33px 46px;
	box-sizing: border-box;
}

.new_container_text_column {
	flex: 0 1 657px;
	display: flex;
	flex-direction: column;
}

.new_date {
	color: #9d9d9d;
	font-family: Geometria;
	font-size: calc((100vw - 414px) / (1920 - 414) * (16 - 11) + 11px);
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
	margin-bottom: 14px;
	text-transform: uppercase;
}

.new_title {
	color: #282882;
	font-family: TestDomaineText;
	font-size: calc((100vw - 414px) / (1920 - 414) * (64 - 32) + 32px);
	font-style: normal;
	font-weight: 700;
	line-height: 125%;
	margin: 0;
	margin-bottom: 40px;
}

.new_text {
	color: #9d9d9d;
	font-family: Geometria;
	font-size: 16px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
	margin: 0;
	max-width: 657px;
	margin-bottom: 20px;
}

.new_container_image_column {
	flex: 0 1 592px;
	max-height: 628px;
	border-radius: 20px;
	overflow: hidden;
}

.new_container_image_column__mobile {
	display: none;
}

.new_image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.link {
	margin-top: 22px;
	display: flex;
	align-items: center;
	gap: 8px;
	text-decoration: none;
}

.link_text {
	color: #626261;
	text-overflow: ellipsis;
	font-family: Geometria;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	transition: color 0.2s ease-in;
}

.link_icon {
	color: #626261;
	transition: color 0.2s ease-in;
	transform: rotate(180deg);
}

.link:hover .link_text,
.link:hover .link_icon {
	color: rgba(236, 94, 67, 1);
}

@media (max-width: 840px) {
	.new_container {
		padding: 21px 25px 31px 30px;
	}

	.new_container_image_column {
		max-height: 316px;
	}

	.new_container_text_column {
		gap: 10px;
	}

	.new_container_image_column {
		display: none;
	}

	.new_container_image_column__mobile {
		display: block;
		height: calc((100vw - 414px) / (1920 - 414) * (300 - 160) + 160px);
		margin-bottom: 20px;
	}

	.new_title {
		margin-bottom: 16px;
	}

	.new_date {
		margin-bottom: 12px;
	}
}
