
.main_container {
  background: #79FF91;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 53px;
}

.spoiler {
  color: #10B42E;
  text-align: center;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.1px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 9px;
}

.title {
  color: #303030;
  text-align: center;
  font-size: calc((100vw - 414px)/(1920 - 414) * (60 - 32) + 32px);
  font-style: normal;
  font-weight: 700;
  line-height: 118.75%;
  font-family: TestDomaineText;
  margin: 0;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (40 - 20) + 20px);
}

.sub_title {
  color: #303030;
  text-align: center;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.1px; 
  max-width: 800px;
  margin: 0;
}

@media (max-width: 600px) {
  .main_container {
    padding-bottom: 64px;
    padding-top: 33px;
  }
}