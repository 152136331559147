.imprint {
	padding-bottom: calc((100vw - 414px) / (1920 - 414) * (115 - 76) + 76px);
  padding-top: calc((100vw - 414px) / (1920 - 414) * (88 - 18) + 18px);

}

.imprint_container {
	max-width: 1470px;
	padding: 0 16px;
	margin: 0 auto;
}

.imprint_text {
	margin-bottom: calc((100vw - 414px) / (1920 - 414) * (90 - 60) + 60px);
}

.imprint_title {
	color: #282882;
	font-family: Inter;
	font-size: 18px;
	line-height: 26px;
	margin-bottom: 30px;
}

.imprint_title_main {
	font-family: Test Domaine Text;
	font-size: calc((100vw - 414px) / (1920 - 414) * (60 - 48) + 48px);
	font-weight: 700;
	line-height: 70px;
	margin-bottom: 20px;
}

.imprint_title_big {
	font-family: Test Domaine Text;
	font-size: 32px;
	font-weight: 700;
	line-height: 70px;
	margin-bottom: 0;
}

.imprint_subtitle {
	color: #4e4e4e;
	font-family: Inter;
	font-size: 15px;
	line-height: 24px;
}

.imprint_subtitle_big {
	/* font-size: calc((100vw - 414px) / (1920 - 414) * (18 - 14) + 14px); */
}

.imprint_items {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 80px;
}

.imprint_item {
	padding: 0 30px;
}

.imprint_item:first-child,
.imprint_item:nth-child(4) {
	padding: 0 30px 0 0;
}


.imprint_inner:not(:last-child) {
	margin-bottom: 45px;
}

.imprint_item {
	position: relative;
}

.imprint_item:not(:last-child):after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 1px;
	background-color: #dfdfdf;
}

.imprint_item:nth-child(3n + 3):after {
	display: none;
}

@media (max-width: 900px) {
	.imprint_items {
		grid-template-columns: 1fr;
		row-gap: 30px;
	}

	.imprint_item:first-child,
	.imprint_item:nth-child(4),
	.imprint_item {
		padding: 0;
		border-bottom: 1px solid #dfdfdf;
		padding-bottom: 30px;
	}

 
	.imprint_item:nth-child(6) {
    border: 0;
	}

  .imprint_item:nth-child(3) {
    border: 0;
	}

	.imprint_item:not(:last-child):after {
		display: none;
	}
}
