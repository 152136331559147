.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
  background: #F5F5F5;
  padding-bottom: calc((100vw - 414px)/(1920 - 414) * (88 - 58) + 58px);
  padding-top: 41px;
}


.title {
  color: #282882;
  font-family: "TestDomaineText";
  font-style: normal;
  font-size: calc((100vw - 414px)/(1920 - 414) * (55 - 32) + 32px);
  font-weight: 700;
  line-height: 120%; 
  /* max-width: 440px; */
  margin: 0 auto;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (40 - 20) + 20px);
  text-align: center;
}

.sub_title {
  color: #4E4E4E;
  font-family: Mont;
  color: #4E4E4E;
  font-weight: 600;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 12) + 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 26.1px; 
  margin: 22px 0 70px;
  margin: 0 auto;
  text-align: center;
  max-width: 920px;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (53 - 40) + 40px);
}

.input_container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 938px;
  position: relative;
}

.result_container_wrapper {

  display: none;
}

.result_container_wrapper__not_hidden {
  display: block;
}

.result_container_wrapper::-webkit-scrollbar {
  width: 8px; 
}

.result_container_wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 30px;

}

.result_container_wrapper::-webkit-scrollbar-thumb {
  background-color: #d4d4d4; 
  border-radius: 30px;

}

.input {
  border-radius: 33px;
  border: 1px solid #282882;
  background: #FFF;
  padding: 8px 0;
  padding-left: 37px;
  padding-right: calc((100vw - 414px)/(1920 - 414) * (166 - 136) + 136px);
  box-sizing: border-box;
  height: 49px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  color: #282882;
  font-family: Mont;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input::placeholder {
  color: #282882;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (14 - 11) + 11px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button_sent {
  z-index: 3;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  margin-left: auto;
  display: block;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (16 - 12) + 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  display: flex;
  width: calc((100vw - 414px)/(1920 - 414) * (172 - 125) + 145px);
  height: 42px;
  padding: 12px 25px 12px 25px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 46px;
  border: 0;
  color: white;
  background: #292982;  
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.button_sent:hover{
  opacity: .8;
}

.button_sent:active{
  background-color: #52f871;
}

.result_container_wrapper {
  /* padding-left: 36px; */
  /* padding-right: 295px; */
  padding-bottom: 28px;
  padding-top: 30px;
  border-radius: 26px;
  border: 1px solid #282882;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  max-height: 312px;
  overflow-y: auto;
}


.symbol {
  margin: 0px 0 20px 0;
  color: #4E4E4E;
  font-family: Mont;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
}

.paragraff {
  margin: 8px 0;
  color: #4E4E4E;
  font-family: Mont;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  line-height: normal;
}

.nested_link {
  margin: 0;
  color: #282882;
  font-family: Mont;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.nested_link__no_link {
  margin: 0;
  color: #282882;
  font-family: Mont;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.links_container {
  max-width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 0 auto;
  flex-direction: column; 
  align-items: center; 
  gap: calc((100vw - 414px)/(1920 - 414) * (29 - 15) + 15px);
}

.links_container {

}


.spiner svg{
  margin: 0 auto;
  color: #EC5E43;
  margin-top: 40px;
}

.link_column {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: none;
  color: #282882;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  transition: color 0.3s ease; 
}

.link_major {
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (16 - 13) + 13px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #282882;
  text-decoration: none;
  transition: color .15s ease;
  max-width: 605px;
  cursor: pointer;
}

.link_major__no_link {
  max-width: 605px;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (16 - 13) + 13px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #282882;
  text-decoration: none;
  transition: color .15s ease;
  cursor: pointer;

}

.link_major__warning {
  margin-top: 46px;
}



.links_container_columns {
  flex: 1;
  width: 100%;
  list-style: none; 
  /* padding-top: 26px; */
  padding-left: calc((100vw - 414px)/(1920 - 414) * (37 - 20) + 20px);
  /* padding-bottom: 18px; */
}

.links_container_columns:first-child {
  padding-top: 40px;
}

.links_container_columns:first-child:has(.accordion_trigger[type=checkbox]:checked) {
  padding-top: 40px;

}

.links_container_columns:has(.accordion_trigger[type=checkbox]:checked) {
  padding-top: 26px;
  padding-bottom: 18px;
}

.link_wrapper {
  text-decoration: none;
  cursor: pointer;
}

.link_wrapper__no_link {
  text-decoration: none;
  cursor: auto;
}

.links_container_columns:hover .link_major {
  color: #EC5E43;
  text-decoration-line: underline;
}

.links_container_columns:hover .nested_link {
  text-decoration-line: underline;
}

.links_container_columns:hover ul {
  display: block;
}

.accordion_content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s, margin .4s;
  list-style: inside;
}

.accordion_label {
  /* width: 605px; */
  display: block;

}

.accordion_trigger[type=checkbox] {
  display: none;
}

.accordion_trigger[type=checkbox]:checked + .accordion_label + .accordion_content {
  max-height: 4000px;
}

.links_container_columns:has(.accordion_trigger[type=checkbox]:checked) {
  background: #F6F6F6;
}

@media (max-width: 600px) {
  .main_container {
    padding-top: 59px;
  }

  .links_container_columns {

    padding-right: 18px;
  }

  .symbol {
    margin: 4px 0 20px 0;
  }
}

@media (max-width: 400px) {
  .input::placeholder {
    font-size: 8px;
  }
  
  .input {
   padding-left: 18px;
   padding-right: 147px;
  }

  .button_sent {
    width: 110px;
  }
}

