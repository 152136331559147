.main_container {
  background: #F5F5F5;
  padding: 0 16px;
  padding-top: calc((100vw - 414px)/(1920 - 414) * (74 - 53) + 53px);
  padding-bottom: calc((100vw - 414px)/(1920 - 414) * (80 - 51) + 51px);

}

.wrapper {
  max-width: 1302px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.spiner {
  margin: 120px auto;
  max-width: 100px;
}

.failed_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 120px auto;
  gap: 12px;
}

.advice_title {
  text-align: center;
}
.category_container {

}

.title {
  color: #282882;
  font-family: TestDomaineText;
  font-size: calc((100vw - 414px)/(1920 - 414) * (60 - 48) + 48px);
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  margin: 0;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (100 - 37) + 37px);
}

.tabs_container {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.tab {
  color: #282882;
  font-family: Inter;
  font-size: calc((100vw - 414px)/(1920 - 414) * (24 - 20) + 20px);
  font-style: normal;
  font-weight: 400;
  line-height: 113.75%;
  text-transform: uppercase;
  max-width: 414px;
  transition: all .2s ease-out;
  cursor: pointer;
}

.tab__active {
  color: rgb(236, 94, 67, 1);
  text-decoration: underline;
}


.content_container {
  max-width: 673px;
}

.accordion {
  max-width: 900px;
  color: #0000f7;
  display: flex;
  flex-flow: column;
  gap: 17px;
  padding: 0;
  margin: 0;
  flex: 1;
  list-style: none;
  /* padding: 16px; */
  border-radius: 10px;
}

.accordion_item {
  list-style: none;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #626261;
  padding: 30px 100px 30px 30px;
  transition: background-color .4s ease-in-out;
  position: relative;
}

.accordion_label {
  color: #282882;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}



.accordion_label::after {
  content: "";
  right: -80px;
  top: 0px;
  position: absolute;
  border: solid rgba(40, 40, 130, 1);
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 5.7px;
  transform: rotate(45deg);
  transition: transform .3s ease-in-out;
}


.accordion_label::before {
  content: url(../../image/close-button-icon.svg);
  right: -82px;
  top: -2px;
  position: absolute;
  display: none;
}


.accordion_text {
  color: #282882;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height:  130%;
  margin: 18px 0 0 0;
  max-width: 532px;
}

.accordion_trigger[type=checkbox] {
  display: none;

}

.accordion_content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .7s, margin .7s;
  list-style: inside;
}

.accordion_trigger[type=checkbox]:checked + .accordion_label::after {
  content: "";
  left: 98%;
  bottom: 45%;
  position: absolute;
  border: solid #3E3E59;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 6px;
  transform: rotate(-135deg);
  display: none;
}

.accordion_trigger[type=checkbox]:checked + .accordion_label::before {

  display: block;
}


.accordion_trigger[type=checkbox]:checked + .accordion_label + .accordion_content {
  max-height: 4000px;
}

.accordion_item:has(.accordion_trigger[type=checkbox]:checked) {
  background-color: #fff;
}


@media (max-width: 930px) {
  
.wrapper {
  flex-direction: column;
  gap: 0;

}

.content_container {
  max-width: none;
  margin-top: 63px;
}

.accordion {
  padding: 0;
}

}