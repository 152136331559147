.footer_wrapper {
  padding: 24px 0 13px;
  width: 100%;
  background: #4E4E4E;
  padding-left: 16px;
  padding-right: 16px;
}

.footer {
  background: #4E4E4E;
  color: #fff;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  max-width: 1440px;
  margin: 0 auto;

  /* box-sizing: border-box; */
}

.links_container {
  max-width: 969px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 30px;
}

.links_container_columns {
  list-style: none;
}

.link_main {
  font-family: TestDomaineText;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  color: rgba(203, 203, 203, 1);
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease; 
  display: flex;
  gap: 16px;
  align-items: center;
  white-space: nowrap;
  cursor:default;
}

/* .link_main:hover {
  color: #EC5E43;
} */

.icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease; 
  color: #fff;
  transform: translateY(4px);
  display: none;
}


.links_container_columns:hover .icon {
  transform: rotate(180deg);
}

.link_main_active {

}

.link_column {
  list-style: none;
  padding: 0;
  display: flex;
  /* margin-top: 12px; */
  flex-direction: column;
}

.link_minor {
  text-decoration: none;
  color: #DADADA;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  transition: color 0.3s ease; 
}


.link_minor:hover {
  color: #EC5E43;
}


.logo_copyright_container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 12px;
}

.logo__mobile {
  display: none;
}

.copyright {
  color: #DADADA;
  font-family: Mont;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; 
  text-align: right;
  margin: 0;
}

/* sssssssssssssssss */

.accordion {
  max-width: 230px;
  color: #f8f8f8;
  display: flex;
  flex-flow: column;
  gap: 12px;
  padding: 0;
  margin: 0;
  flex: 1;
  list-style: none;
  border-radius: 10px;
  display: none;
}

.accordion_item {
  list-style: none;
  width: 100%;
  border-radius: 20px;
  transition: background-color .4s ease-in-out;
  position: relative;

}

.accordion_label {
  cursor: pointer;
  box-sizing: border-box;
  font-family: TestDomaineText;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height:  218.75%;
  color: rgba(203, 203, 203, 1);
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease; 
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
}

.accordion_label__hidden {
  cursor: pointer;
  box-sizing: border-box;
  font-family: TestDomaineText;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height:  218.75%;
  color: rgba(203, 203, 203, 1);
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease; 
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
}




@media (hover:hover) {
  .accordion_label__hidden:hover {
    color: #EC5E43;
  }
  
  .accordion_label:hover {
    color: #EC5E43;
  }
}


@media (hover:none) {
  .accordion_label__hidden:active {
    color: #EC5E43;
  }
  
  .accordion_label:active {
    color: #EC5E43;
  }
}

.accordion_label::after {
  content: "";
  right: 38px;
  top: 27px;
  position: absolute;
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 5.7px;
  transform: rotate(45deg);
  transition: transform .3s ease-in-out;
  visibility: hidden;
}

.accordion_label__hidden::after {
  content: "";
  right: 38px;
  top: 27px;
  position: absolute;
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 5.7px;
  transform: rotate(45deg);
  transition: transform .3s ease-in-out;
  visibility: hidden;
}


.accordion_text {
  color: #282882;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height:  130%;
  margin: 18px 0 0 0;
  max-width: 532px;
}

.accordion_trigger[type=checkbox] {
  display: none;
}

.accordion_content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out, margin .3s ease-in-out;
  list-style: inside;
}

/* .accordion_trigger[type=checkbox]:checked +  */
.accordion_label::after {
  content: "";
  position: absolute;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 6px;
  transform: rotate(45deg);
  visibility: visible;
}

.accordion_trigger[type=checkbox]:checked + .accordion_label__hidden::after {
  content: "";
  position: absolute;
  border: solid #cc3737;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 6px;
  transform: rotate(45deg);
  visibility: hidden;
}


.accordion_trigger[type=checkbox]:checked + .accordion_label::before {
  display: block;
}


.accordion_trigger[type=checkbox]:checked + .accordion_label__hidden::before {
  display: none;
}

.accordion_trigger[type=checkbox]:checked + .accordion_label + .accordion_content {
  /* max-height: 4000px; */
}

.accordion_item:has(.accordion_trigger[type=checkbox]:checked) {
  /* background-color: #fff; */
}


@media (max-width: 980px) {
  .footer_wrapper {
    padding-bottom: 0;
  }
  .footer {
    flex-direction: column;
    gap: 60px;
    padding-left: 20px;
    padding-bottom: 10px;
  }

  .links_container {
    flex-direction: column; 
    align-items: flex-start; 
    gap: 24px;
    padding-left: 16px;
    display: none;
  }

  .accordion {
    display: flex;
  }

  .links_container_columns {
    margin-top: 10px;
  }

  .links_container_columns ul {
    display: none;
    transition: all 1s ease-in;
  }

  .links_container_columns:hover ul {
    display: block;
  }

  .logo_copyright_container {
    align-self: flex-start;
  }

  .logo__mobile {
    display: block;
  }

  .icon {
    display: block;
  }
  
  .logo {
    display: none;
  }

  .copyright {
    align-self: flex-start;
  }
}