
@font-face { 
  font-family: "Geometria";
  src: url("./fonts/GeometriaRegular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
/* Mont-Bold */
@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

/* Mont-BoldItalic */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-BoldItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
} */

@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

/* Mont-Book */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-Book.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
} */

/* Mont-BookItalic */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-BookItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
} */

/* Mont-ExtraLight */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
} */

/* Mont-ExtraLightItalic */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-ExtraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
} */

/* Mont-Hairline */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-Hairline.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
} */

/* Mont-HairlineItalic */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-HairlineItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
} */

/* Mont-Heavy */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-Heavy.otf') format('opentype');
   font-weight: 900;

  font-style: normal;
} */

/* Mont-HeavyItalic */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-HeavyItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
} */

/* Mont-Light */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-Light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
} */

/* Mont-LightItalic */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-LightItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
} */

/* Mont-Regular */
@font-face { 
  font-family: "Mont";
  src: url("./fonts/Mont/MontRegular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

/* Mont-RegularItalic */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-RegularItalic.otf') format('opentype');
  font-weight: 900;

  font-style: italic;
} */

/* Mont-SemiBold */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-SemiBold.otf') format('opentype');
   font-weight: 900;

  font-style: normal;
} */

/* Mont-SemiBoldItalic */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-SemiBoldItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
} */

/* Mont-Thin */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-Thin.otf') format('opentype');
   font-weight: 900;

  font-style: normal;
} */

/* Mont-ThinItalic */
/* @font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/Mont-ThinItalic.otf') format('opentype');
   font-weight: 900;
  font-style: italic;
} */

@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont/montserrat-medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}




/* @font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/TestDomaineText-Black.woff2") format("woff2"), url("./fonts/TestDomaineText-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
} */
/* @font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/TestDomaineText-BlackItalic.woff2") format("woff2"), url("./fonts/TestDomaineText-BlackItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
@font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/test-domaine-text-bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
}

/* @font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/test-domaine-text-black.woff2") format("woff2");
	font-weight: 900;
	font-style: normal;
} */
/* @font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/TestDomaineText-LightItalic.woff2") format("woff2"), url("./fonts/TestDomaineText-LightItalic.woff") format("woff");
	font-weight: 400;
	font-style: italic;
} */
/* @font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/TestDomaineText-BoldItalic.woff2") format("woff2"), url("./fonts/TestDomaineText-BoldItalic.woff") format("woff");
	font-weight: 400;
	font-style: italic;
} */
@font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/TestDomaineText-Medium.woff2") format("woff2"), url("./fonts/TestDomaineText-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/TestDomaineText-Light.woff2") format("woff2"), url("./fonts/TestDomaineText-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/TestDomaineText-Regular.woff2") format("woff2"), url("./fonts/TestDomaineText-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
/* @font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/TestDomaineText-MediumItalic.woff2") format("woff2"), url("./fonts/TestDomaineText-MediumItalic.woff") format("woff");
	font-weight: 400;
	font-style: italic;
} */
/* @font-face {
	font-family: TestDomaineText;
	font-display: swap;
	src: url("./fonts/TestDomaineText-RegularItalic.woff2") format("woff2"), url("./fonts/TestDomaineText-RegularItalic.woff") format("woff");
	font-weight: 400;
	font-style: italic;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-Black.woff2") format("woff2"), url("./fonts/TTInterfaces-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-BlackItalic.woff2") format("woff2"), url("./fonts/TTInterfaces-BlackItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-BoldItalic.woff2") format("woff2"), url("./fonts/TTInterfaces-BoldItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
@font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-Bold.woff2") format("woff2"), url("./fonts/TTInterfaces-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-ExtraBold.woff2") format("woff2"), url("./fonts/TTInterfaces-ExtraBold.woff") format("woff");
	font-weight: 800;
	font-style: normal;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-ExtraBoldItalic.woff2") format("woff2"), url("./fonts/TTInterfaces-ExtraBoldItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-ExtraLightItalic.woff2") format("woff2"), url("./fonts/TTInterfaces-ExtraLightItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-ExtraLight.woff2") format("woff2"), url("./fonts/TTInterfaces-ExtraLight.woff") format("woff");
	font-weight: 200;
	font-style: normal;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-LightItalic.woff2") format("woff2"), url("./fonts/TTInterfaces-LightItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-Italic.woff2") format("woff2"), url("./fonts/TTInterfaces-Italic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
@font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-Light.woff2") format("woff2"), url("./fonts/TTInterfaces-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-Medium.woff2") format("woff2"), url("./fonts/TTInterfaces-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-MediumItalic.woff2") format("woff2"), url("./fonts/TTInterfaces-MediumItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
@font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-Regular.woff2") format("woff2"), url("./fonts/TTInterfaces-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-SemiBold.woff2") format("woff2"), url("./fonts/TTInterfaces-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-SemiBoldItalic.woff2") format("woff2"), url("./fonts/TTInterfaces-SemiBoldItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-Thin.woff2") format("woff2"), url("./fonts/TTInterfaces-Thin.woff") format("woff");
	font-weight: 100;
	font-style: normal;
} */
/* @font-face {
	font-family: TTInterfaces;
	font-display: swap;
	src: url("./fonts/TTInterfaces-ThinItalic.woff2") format("woff2"), url("./fonts/TTInterfaces-ThinItalic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
} */
