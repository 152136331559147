.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 79px 0 108px;
  padding-left: 16px;
  padding-right: 16px;
  background: #8DE99D;
  padding-top: calc((100vw - 414px)/(1920 - 414) * (79 - 33) + 33px);
  padding-bottom: calc((100vw - 414px)/(1920 - 414) * (108 - 51) + 51px);
}

.spoiler {
  color: #10B42E;
  text-align: center;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight:600;
  line-height: 26.1px; /* 145% */
  text-transform: uppercase;
  margin: 0 auto;
  margin-bottom: 9px;
}

.title {
  color: #282882;  
  text-align: center;
  font-family: TestDomaineText;
  font-size: calc((100vw - 414px)/(1920 - 414) * (55 - 32) + 32px);
  font-style: normal;
  font-weight: 800;
  line-height: 127.273%;
  margin: 0 auto;
  max-width: 580px;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (53 - 20) + 20px);
}

.sub_title {
  font-family: Inter;
  color: #4E4E4E;
  text-align: center;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 12) + 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 26.1px;
  margin: 0 auto;
  max-width: 600px;
}

.contact_container {
  padding: 0;
  list-style: none;
  display: flex;
  gap: calc((100vw - 414px)/(1920 - 414) * (77 - 57) + 57px);
  margin-top: calc((100vw - 414px)/(1920 - 414) * (64 - 51) + 51px);
}

.contact_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_item_title {
  text-align: center;
  margin: 0;
  margin-top: calc((100vw - 414px)/(1920 - 414) * (19 - 12) + 12px);
  color: #4E4E4E;
  text-align: center;
  font-family: TestDomaineText;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 12) + 12px);
  font-style: normal;
  font-weight: 700;
  line-height: 26.1px; 
  text-transform: uppercase;

}

.image_wrapper {
  width: calc((100vw - 414px)/(1920 - 414) * (169 - 141) + 141px);
  height: calc((100vw - 414px)/(1920 - 414) * (169 - 141) + 141px);
  overflow: hidden;
  border-radius: 50%;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
}

.button_container {
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: calc((100vw - 414px)/(1920 - 414) * (70 - 52) + 52px);
  gap: 29px;
  list-style: none;
}

.button {
  color: #4E4E4E;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 112.5%;
  text-transform: uppercase;
  border-radius: 46px;
  width: 290px;
  height: 75px;
  border: 1px solid #434343;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  transition: all .2s ease-out;
  cursor: pointer;
}

.button:hover {
  background: #282882;
  color:  #fff;
}

.link {
  margin: 28px 0;
  text-decoration: none;
  color: #4E4E4E;
  height: 100%;
  text-align: center;
  display: block;
 
}

.button:hover .link {
  color:  #fff;
}

@media (max-width: 990px) {
  .button_container {
    flex-direction: column;
  }  
}

@media (max-width: 413px) {
  .contact_container {
    gap: 25px;
  }

  .image_wrapper {
    width: 100px;
    height: 100px;
  }

  .button {
    color: #4E4E4E;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 112.5%;
    text-transform: uppercase;
    border-radius: 46px;
    width: 240px;
    height: 65px;
  }

  .link {
    margin: 24px 0 18px;
    text-decoration: none;
    color: #4E4E4E;
    height: 100%;
    text-align: center;
    display: block;
   
  }
  
}

