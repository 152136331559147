.wrapper {
  margin: 0 auto;

}

.background {
  background-color: #79FF91;
  padding-bottom: 1px;
  padding-top: 32px;
  padding: 28px;
  padding: 28px;
}

.spoiler {
  color: #30D94E;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  text-transform: uppercase;
  margin-bottom: 6px;
  text-align: center;
}

.title {
  color: #303030;
  font-family: TestDomaineText;
  font-style: normal;
  font-size: calc((100vw - 414px)/(1920 - 414) * (60 - 32) + 32px);
  font-style: normal;
  font-weight: 600;
  line-height: 120%; 
  max-width: 796px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}

.sub_title {
  color: #4E4E4E;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 12) + 12px);
  font-style: normal;
  font-weight: 200;
  line-height: 26.1px; 
  margin: 22px 0 70px;
  margin: 0 auto;
  text-align: center;
  max-width: 1040px;
  margin-bottom: 48px;
}

.title_second {
  color: #282882;
  font-family: TestDomaineText;
  font-size: calc((100vw - 414px)/(1920 - 414) * (30 - 24) + 24px);
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 
  margin: 0;
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;
  margin-top: calc((100vw - 414px)/(1920 - 414) * (63 - 30) + 30px);
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (24 - 20) + 20px);
  /* margin-right: 28px;
  margin-left: 28px; */
}

.title_second__no_uper {
  text-transform: lowercase;

}

.sub_title_second {
  color: #4E4E4E;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 12) + 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 20.1px; 
  margin: 22px 0 70px;
  margin: 0 auto;
  text-align: center;
  max-width: 650px;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (46 - 30) + 30px);
  /* margin-right: 28px;
  margin-left: 28px; */
}

.spiner {
  margin: 0 auto;
  max-width: 100px;
}

.failed_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  gap: 12px;
}

.error_sign {

}

.advice_container {
  list-style: none;
  padding: 0 16px;
  margin: 0 auto;
  max-width: 1330px;
  display: grid;
  grid-template-columns: repeat(2, 49%);
  grid-template-rows: repeat(4, 1fr);
  gap: 24px;
  padding-bottom: calc((100vw - 414px)/(1920 - 414) * (46 - 30) + 30px);
}

.advice_item {
  padding: 65px 18px 24px 39px;
  border-radius: 20px;
  border: 1px solid #DFDFDF;
  box-shadow: 0px 8px 26px 0px rgba(203, 184, 184, 0.19);
  max-width: 635px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
}

.advice_title {
  color: #282882;
  font-family: TestDomaineText;
  font-size: calc((100vw - 414px)/(1920 - 414) * (24 - 19) + 19px);
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  text-transform: uppercase;
  /* word-break: break-all; */
  /* white-space: pre-line; */
  margin: 0;

}

.advice_title__warning {
  font-size: calc((100vw - 414px)/(1920 - 414) * (20 - 16) + 16px);
  line-height: 120%;
  font-family: Inter;
  text-align: center;
  color: #9c9c9c;
  margin: 0 16px;
}

.advice_sub_title {
  color: #4E4E4E;
  font-family: Inter;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 14) + 14px);
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin: 0;
  max-width: 578px;
  word-wrap: break-word; 

}

.figure_direct {
  width: 51px;
  height: 2px;
  background: #64D79E;
}

@media (max-width: 800px) {
  .advice_container {
    grid-template-columns: 1fr;
    gap: 8px;
    justify-items: center;
  }

  .advice_item {
    padding: 65px 18px 24px 23px;
  }

  .title_second ,.sub_title_second {
    margin-right: 28px;
    margin-left: 28px;
  }


  .sub_title {
    margin-bottom: 60px;
  }
}

@media (max-width: 440px) {
  .advice_item {
    padding: 65px 15px 24px 18px;
  }
}