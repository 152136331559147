.main_container {
  display: grid;
  grid-template-columns: minmax(300px, 680px) minmax(337px, 900px);
  grid-template-rows: auto auto;
  max-width: 1470px;
  padding: 0 16px;
  /* padding-left: calc((100vw - 414px)/(1920 - 414) * (50 - 16) + 16px); */
  padding-top: calc((100vw - 414px)/(1920 - 414) * (90 - 40) + 40px);
  box-sizing: border-box;
  margin: 0 auto;
}

.title {
  color: #282882;
  font-family: TestDomaineText;
  font-size: calc((100vw - 414px)/(1920 - 414) * (55 - 32) + 32px);
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin: 0;
}

.sub_title {
  color: #4E4E4E;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 11) + 11px);
  font-style: normal;
  font-weight: 500;
  line-height: 181.818%; 
  margin-top: calc((100vw - 414px)/(1920 - 414) * (22 - 16) + 16px);
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (70 - 10) + 10px);
}

.button_container {
  display: flex;
  padding: 0;
  margin: 0;
  gap: calc((100vw - 414px)/(1920 - 414) * (29 - 16) + 16px);
  list-style: none;
  grid-column: 1/2;
  grid-row: 2/3;
}

.button_container_item {
  color: #EC5E43;
  text-align: center;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (16 - 15) + 15px);
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 46px;
  line-height: 145%; 
  width: calc((100vw - 414px)/(1920 - 414) * (290 - 170) + 170px);
  height: calc((100vw - 414px)/(1920 - 414) * (75 - 60) + 60px);
  border: 1px solid #EC5E43;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-out;
  text-decoration: none;
}

.button_container_item:hover {
  background: #EC5E43;
  color:  #fff;
}

.second_column_container {
  width: 90%;
  grid-row: 1/3;
  max-height: 641px;
  position: relative;
  /* bottom: 80px; */
  left: calc((100vw - 414px)/(1920 - 414) * (70 - 36) + 36px);
}

.second_column_container__desktop {

}

.second_column_container__mobile {
  display: none;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


/* @media (max-width:) {
  .second_column_container {
    right: 300px;
  }
} */


@media (max-width: 977px) {

  .main_container {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
  }

  .title , .sub_title {
    margin: 0 auto;
    text-align: center;
  }

  .sub_title {
    margin-top: calc((100vw - 414px)/(1920 - 414) * (22 - 16) + 16px);
    margin-bottom: calc((100vw - 414px)/(1920 - 414) * (70 - 10) + 10px);
  }

  .second_column_container {
    margin-top: 12px;
    grid-row: 2/3;
    max-height: 427px;
    left: 11%;
  }

  .second_column_container {

  }

  .button_container {
    grid-row: 3/4;
    margin: 0 auto;
    margin-top: 9px;
  }


  .image {
    object-fit: contain;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .second_column_container__mobile {
    display: block;
  }
  
  .second_column_container__desktop {
    display: none;
  }
}

@media (max-width: 600px) {
  .button_container {
    flex-direction: column;
  }
  .button_container_item {
    width: 290px;
    height: 75px;
  }
  
}

@media (max-width: 420px) {
  .second_column_container {
    width: 301px;
    height: 230px;
  }

}

@media (max-width: 414px) {
  .button_container {
    flex-direction: column;
    margin: 0 auto;
    margin-top: 9px;
  }

  .second_column_container {
    left: 17%;
  }
}

@media (max-width: 413px) {
  .second_column_container {
    left: 11%;
  }
}

@media (max-width: 370px) {
  .second_column_container {
    left: 11%;
    width: 241px;
    height: 230px;
  }

}