.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 50px;

  padding-bottom: calc((100vw - 414px)/(1920 - 414) * (104 - 50) + 50px);
}

.spoiler {
  color: #BEBEDA;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  text-transform: uppercase;
  margin-bottom: 6px;
  margin-top: 50px;
}

.title {
  color: #282882;
  font-family: TestDomaineText;
  font-size: 55px;
  font-style: normal;
  font-size: calc((100vw - 414px)/(1920 - 414) * (55 - 32) + 32px);
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 
  max-width: 580px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (40 - 20) + 20px);
  text-align: center;
}

.sub_title {
  color: #4E4E4E;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 12) + 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 26.1px; 
  margin: 22px 0 70px;
  margin: 0 auto;
  text-align: center;
  max-width: 640px;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (40 - 30) + 30px);
}

.process_container {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  max-width: 1105px;
  box-sizing: border-box;
  column-gap: 18px;
  row-gap: 23px;
  
}

.process_item {
  flex: 0 0 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: #F9F9F9;
  box-shadow: 0px 8px 26px 0px rgba(203, 184, 184, 0.19);
  padding-left: calc((100vw - 414px)/(1920 - 414) * (41 - 27) + 27px);
  padding-right: calc((100vw - 414px)/(1920 - 414) * (41 - 26) + 26px);
  padding-top: calc((100vw - 414px)/(1920 - 414) * (56 - 35) + 35px);
  padding-bottom: calc((100vw - 414px)/(1920 - 414) * (73 - 35) + 35px);
  box-sizing: border-box;
}

.number_curcle {
  color: #444;
  font-family: TestDomaineText;
  font-size: 62px;
  font-style: normal;
  font-weight: 400;
  line-height: 158.065%;
  width: calc((100vw - 414px)/(1920 - 414) * (116 - 81) + 81px);
  height: calc((100vw - 414px)/(1920 - 414) * (116 - 81) + 81px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC0B4;
}

.process_item_title {
  /* flex: 1; */
  color: #282882;
  font-family: TestDomaineText;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 113.75%;
  text-transform: uppercase;
  text-align: center;
  margin-top: calc((100vw - 414px)/(1920 - 414) * (45 - 19) +  19px);
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (32 - 27) + 27px);
}

.process_item_sub_title {
  color: #4E4E4E;
  font-family: 'Inter';
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 12) + 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 26.1px;
  max-width: 498px;
  text-align: center;
}

.button_container {
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: calc((100vw - 414px)/(1920 - 414) * (60 - 30) + 30px);
  gap: 29px;
  list-style: none;
}

.button_container_item {
  color: #EC5E43;
  text-align: center;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 46px;
  width: 290px;
  height: 75px;
  border: 1px solid #EC5E43;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  transition: all .2s ease-out;
}

.link {
  text-decoration: none;
  color: #EC5E43;
  display: block;
  height: 100%;
  margin: 27px 0;
}

.button_container_item:hover {
  background: #EC5E43;
  color:  #fff;
}

.button_container_item:hover .link {
  color:  #fff;
}

.wrapper {
  width: calc((100vw - 414px)/(1920 - 414) * (600 - 300) + 300px);
  display: none !important;
  background: #F9F9F9;
  box-shadow: 0px 8px 26px 0px rgba(203, 184, 184, 0.19);
  /* background-color: #FFC0B4; */
}


@media (max-width: 1024px) {
  .process_container {
    display: none;
  }
  .process_item {
    box-shadow: none;
    /* background-color: aquamarine; */
  }
  
  .wrapper {
    display: block !important;
   } 
   .wrapper div {
    margin: 0 auto;
   }
   .wrapper {
    border-radius: 20px;
   }

   .button_container {
    flex-direction: column;
   }

}

@media (max-width: 624px) {

  .process_container {
  display: none;
  }
  .wrapper {
  display: block !important;
  width: 100%;

  } 
 .wrapper div {
  margin: 0 auto;
  }
 .wrapper {
  border-radius: 20px;
  }

 .button_container {
  flex-direction: column;
  display: none;
 }

}

