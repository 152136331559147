.background {
  background-color: #79FF91;
  padding-bottom: 1px;
  padding-top: 32px;
  padding-right: 8px;
  padding-left: 8px;
}

.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: calc((100vw - 414px)/(1920 - 414) * (57 - 30) + 30px);
  padding-top: calc((100vw - 414px)/(1920 - 414) * (35 - 30) + 30px);
}

.spoiler {
  color: #30D94E;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  text-transform: uppercase;
  margin-bottom: 6px;
  text-align: center;
}

.title {
  color: #303030;
  font-family: TestDomaineText;
  font-size: 55px;
  font-style: normal;
  font-size: calc((100vw - 414px)/(1920 - 414) * (60 - 32) + 32px);
  font-style: normal;
  font-weight: 600;
  line-height: 120%; 
  max-width: 630px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}

.sub_title {
  color: #4E4E4E;
  font-family: Mont;
  font-size: calc((100vw - 414px)/(1920 - 414) * (18 - 12) + 12px);
  font-style: normal;
  line-height: 26.1px; 
  margin: 22px 0 70px;
  margin: 0 auto;
  text-align: center;
  max-width: 1397px;
  margin-bottom: calc((100vw - 414px)/(1920 - 414) * (48 - 33) + 33px);
  color: #303030;
  font-weight: 400;
}

.process_container {
  list-style: none;
  display: flex;
  flex-direction: column;
  column-gap: 18px;
  row-gap: 9px;
  max-width: 1387px;
}

.process_item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 20px;
  background: #F9F9F9;
  box-shadow: 0px 8px 26px 0px rgba(203, 184, 184, 0.19);
  padding: 73px 69px 73px 63px;
  gap: 27px;
  max-width: 1367px;
}

.number_curcle {
  color: #444;
  font-family: TestDomaineText;
  font-size: 62px;
  font-style: normal;
  font-weight: 400;
  line-height: 98px; 
  width: 116px;
  height: 116px;
  min-width: 116px;
  min-height: 116px;
  border-radius: 50%;
  background-color: #FFC0B4;
  position: relative;

}

.number_curcle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.process_item_title {
  flex: 1;
  color: #282882;
  font-family: TestDomaineText;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.3px; 
  text-transform: uppercase;
  text-align: left;
  margin: 0;

}

.process_item_sub_title {
  flex: 0 0 46%; 
  color: #4E4E4E;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.1px;
  text-align: left;
  margin: 0;
}

.button_container {
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: calc((100vw - 414px)/(1920 - 414) * (57 - 30) + 30px);
  gap: 29px;
  list-style: none;
}

.button_container_item {
  color: #EC5E43;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  text-transform: uppercase;
  border-radius: 46px;
  width: 290px;
  height: 75px;
  border: 1px solid #EC5E43;
 
  transition: all .2s ease-out;
}


.link {
  padding:  27px 0;
  color: #EC5E43;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  text-transform: uppercase;
  border-radius: 46px;
  width: 290px;
  height: 75px;
  border: 1px solid #EC5E43;
 
  transition: all .2s ease-out;
  display: block;
  text-decoration: none;
}

.link:hover {
  background: #EC5E43;
  color:  #fff;
}


.wrapper {
  width: calc((100vw - 414px)/(1920 - 414) * (600 - 300) + 300px);
  display: none !important;
}



@media (max-width: 1024px) {

 .button_container {
  flex-direction: column;
 }

}

@media (max-width: 860px) {

  .process_item {
    flex-direction: column;
    align-items: flex-start;
    padding: 73px 37px 57px 27px;
    gap: 24px;


  }

  .button_container {
    flex-direction: column;
  }

  .spoiler {
    margin-bottom: 16px;
  }

}

