.main_container {
  max-width: 1287px;
  margin: 0 auto;
  padding: 0 16px;
  padding-bottom: calc((100vw - 414px)/(1920 - 414) * (108 - 50) + 50px);
  padding-top: calc((100vw - 414px)/(1920 - 414) * (40 - 30) + 30px);
}

.main_container__function {
  max-width: 1287px;
  margin: 0 auto;
  padding: 0 16px;
  padding-bottom: calc((100vw - 414px)/(1920 - 414) * (108 - 50) + 50px);
  padding-top: calc((100vw - 414px)/(1920 - 414) * (40 - 30) + 30px);
}

.tarif_container {
  list-style: none;
  margin: 0;
  padding: 0 16px;
  border-radius: 20px;
  background: #FFF;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  /* gap:  calc((100vw - 414px)/(1920 - 414) * (40 - 20) + 20px); */
  height: auto;
}

.swiper_slide {
  height: auto !important;
}

.tarif_item {
  /* width: 33%; */
  max-width: 387px;
  flex: 1 1 387px;
  border-radius: 20px;
  border: 1px solid #BEBEDA;
  padding-bottom: 31px;
  padding-right: calc((100vw - 414px)/(1920 - 414) * (23 - 22) + 22px);
  padding-left: calc((100vw - 414px)/(1920 - 414) * (23 - 22) + 22px);

}

.tarif_item__mobile {
  height: 100%;

}

.tarif_item__active {
  border: 1px solid #EC5E43;
}

.title {
  color: #282882;
  font-family: TestDomaineText;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 45.5px; 
  text-align: center;
  margin-top: calc((100vw - 414px)/(1920 - 414) * (24 - 18) + 18px);
}

.sub_title {
  color: #4E4E4E;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; 
  text-align: center;
  margin: 10px 0 24px;
}


.features_container {
  display: flex;
  flex-direction: column;
  gap: 29px;
  list-style: none;
  padding: 0;
  padding: 44px 0 0;
  margin: 0;
  border-top: 1px solid #DFDFDF;
  max-width: 387px;
  box-sizing: border-box;
}



.features_item {
  display: flex;
  align-items: center;
  max-width: 387px;
}

.feature_text {
  margin: 0;
  color: #282882;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.1px; 
  /* word-wrap: break-word;
  word-break: break-all;  */
      
}

.feature_text__unallowable {
  color: #9393C1;
}

.sign__allowable {
  color: #64D79E;
  margin-right: calc((100vw - 414px)/(1920 - 414) * (20 - 10) + 10px);
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.sign__unallowable {
  color: #EC5E43;
  margin-right: calc((100vw - 414px)/(1920 - 414) * (20 - 10) + 10px);
  min-width: 18px;
  min-height: 18px;
}

.price {
  text-align: center;
  color: #282882;
  font-family: TestDomaineText;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  margin-top: calc((100vw - 414px)/(1920 - 414) * (73 - 71) + 71px);
  margin-bottom: 37px;
}


.price_value {
  text-align: center;
  font-family: TestDomaineText;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 80.556%;
  color: #282882;
}

.button {
  background: #282882;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  border-radius: 46px;
  width: calc((100vw - 414px)/(1920 - 414) * (290 - 287) + 287px);
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .2s ease-out, border .02s ease-out;
  margin: 0 auto;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  background: #EC5E43;
  border: 1px solid #EC5E43;
  text-align: center;
}

.button__special {
  background: #EC5E43;
  border: 1px solid #EC5E43;
  text-align: center;
}

.button__special:hover {
  background: #282882;
  border: 1px solid #282882;
  text-align: center;
}


.button__subtitle {
  text-align: center;
  color: #626261;
  text-align: center;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin: 0;
}

.wrapper {
  display: none !important;
  max-width: calc(383px + 383px);
  height: 100%;
  max-width: 0 auto;
}

.spiner {
  margin: 0 auto;
  max-width: 100px;
}

.failed_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  gap: 12px;
}

.advice_title {
  text-align: center;
}

@media (max-width:1260px) {
  .tarif_container {
    gap:  calc((100vw - 414px)/(1920 - 414) * (30 - 20) + 20px);
  }
}


@media (max-width: 1124px) {
  .tarif_container {
    display: none;
  }

  .tarif_item {
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    display: flex !important;
    height: 100%; 
    border-radius: 20px;
    padding: 0 0 40px 0 !important;
  } 

  .wrapper div {
    margin: 0 auto;
  }
}

@media (max-width: 820px) {
  .main_container {
    padding: 0 16px;
    padding-bottom: calc((100vw - 414px)/(1920 - 414) * (108 - 50) + 50px);
    padding-top: calc((100vw - 414px)/(1920 - 414) * (40 - 30) + 30px);
  }
  
  .main_container {
    padding-left: 16px;
  }
}

@media (max-width: 620px) {
  .wrapper {
    max-width: 383px;
  }

  .sub_title {
    margin: 10px 0 29px;
  }
}

@media (max-width: 383px) {
  /* .sign__allowable {
    margin-right: 12px;
  }

  .sign__unallowable {
    margin-right: 12px;
  } */
  .button {
    width: 250px
  }

  .feature_text {
    font-size: 16px; 
  }

}